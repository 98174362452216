import React from 'react';

export default function Datenschutz({ ...props }) {

    React.useEffect(() => {
    }, []);

    return (
        <div className="parallax-bg" id="impressum">
            <div className="container pt-4">
                <div className="row">
                    <div className="col-12">
                        <h1>Impressum</h1>
                        <strong>Angaben gemäß § 5 TMG</strong>
                        <br />
                        <br />
                        <p>Daniel Wildförster <br />
                            Große Haken Straße 42 <br />
                            42283 Wuppertal</p>
                        <p></p>
                        <strong>Kontakt</strong>
                        <p>
                        <a href="tel:01703571907">01703571907</a><br />
                            E-Mail: <a href="mailto:daniel.wildfoerster@gmail.com">daniel.wildfoerster@gmail.com</a>
                        </p>
                        <strong>Verantwortlicher</strong>
                        <p>Inhaltlich Verantwortlicher gemäß § 18 Abs. 2 MStV: Daniel Wildförster</p>
                        <strong> Hinweise: </strong>
                        <p>
                            Kleinunternehmer i. S. v. § 19 Abs. 1 UStG haben ein Wahlrecht, ob sie Umsatzsteuer abführen wollen. Entscheiden sie sich gegen die Abführung, benötigen sie grundsätzlich keine Umsatzsteuer-Identifikationsnummer. Verfügen sie über keine Umsatzsteuer-Identifikationsnummer, müssen sie auch im Impressum keine solche angeben. Hinzu kommt, daß Kleinunternehmer häufig nicht ins Handelsregister eingetragen sind, so daß auch insoweit keine Angaben erforderlich sind. Besteht allerdings eine Registereintragung oder wurde eine Umsatzsteuer-Identifikationsnummer erteilt, sind die entsprechenden Angaben zu machen. Das Muster geht davon aus, daß keine Umsatzsteuer-Identifikationsnummer vorliegt und daß der Betreiber der Internetseite nicht ins Handelsregister eingetragen ist. Für das Muster wurde ferner von einem journalistisch-redaktionell gestalteten Angebot ausgegangen, so daß die Anbieterkennzeichnung nach § 18 Abs. 2 MStV gesondert aufgenommen wurde.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
