import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Logo from './assets/logo.png';
import InstagramImage from './assets/instagram_icon.svg';
import FacebookImage from './assets/facebook_icon.svg';
import TikTokImage from './assets/tiktok_icon.svg';
import WhatsApp from './assets/wicon.png'
import AudiImage from './assets/ville-kaisla-HNCSCpWrVJA-unsplash.jpg';
import SmokeElement from "smoke-effect-react";
import Gallery from './components/gallery';
import Contact from './components/contact';
import Datenschutz from './pages/datenschutz';
import Impressum from './pages/impressum';
import { Helmet } from "react-helmet";
import Scrollspy from 'react-scrollspy'
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { createBrowserHistory } from "history";

const customHistory = createBrowserHistory();

function App({ ...props }) {
  const [state, setState] = React.useState({ sticky: true });
  const listenScrollEvent = e => {
    if (window.scrollY < 30) {
      setState({ sticky: true });
    } else {
      setState({ sticky: false });
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
  }, []);

  return (
    <Router history={customHistory}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>💦🚗 Wildförster - Fahrzeugaufbereiterer</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <img className="logo" src={Logo} height="150" width="150" alt="logo" />
      <header className="header_bg">
        <div className="smoke" >
          <SmokeElement
            smokeSrc="https://s3-us-west-2.amazonaws.com/s.cdpn.io/95637/Smoke-Element.png"
            smokeOpacity={0.1}
          />
        </div>
      </header>
      <nav className={`nav ${!state.sticky && "static"}`} style={{ background: state.sticky ? "rgba(0,0,0,0)" : "rgba(0,0,0,1)", }}>
        <div className="container-fluid">
          <div className="d-flex flex-row justify-content-center h-100">
            <div className="d-flex col-6 justify-content-start align-items-center">
              <div className="menu" onClick={() => console.log("nein!")}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              {customHistory ?
                (
                  <Scrollspy items={['aboutus', 'contact', 'works']} currentClassName="active" className="nav-menu">
                    <li className=""><a onClick={() => customHistory.replace("/")} href="#aboutus">#über uns</a></li>
                    <li><a onClick={() => customHistory.replace("/")} href="#contact">#kontakt</a></li>
                    <li><a onClick={() => customHistory.replace("/")} href="#works">#works</a></li>
                  </Scrollspy>
                ) : null
              }
            </div>
            <div className="d-flex col-6 justify-content-end align-items-center social-icons">
              <a href="https://m.facebook.com/Daniel-Wildf%C3%B6rster-113290246971839/" target="_blank" rel="noreferrer"><img className="" src={FacebookImage} alt="facebook" /></a>
              <a href="https://www.instagram.com/wildforsterdaniel/?hl=de" target="_blank" rel="noreferrer"><img className="" src={InstagramImage} alt="instagram" /></a>
              <a href="https://www.tiktok.com/@wildfoerster13?" target="_blank" rel="noreferrer"><img className="" src={TikTokImage} alt="tiktok" /></a>
            </div>
          </div>
        </div>
      </nav>
      <Switch>
        <Route path="/impressum" exact>
          <Impressum />
        </Route>
        <Route path="/datenschutz" exact>
          <Datenschutz />
        </Route>
        <Route path="/">
          <div className="parallax-bg d-flex justify-content-center align-items-center" id="aboutus">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-lg-6 p-md-4 pb-4 pt-4">
                  <LazyLoadImage
                    height="100%" width="100%" className="img-fluid" alt="audi"
                    effect="blur"
                    src={AudiImage} />
                </div>
                <articel className="col-12 col-lg-6 p-md-4 ">
                  <h1 className="p-md-4 pb-4 text-center text-md-start">Mit <b>WILDFÖRSTER</b> hast Du den richtigen <b>Fahrzeugaufbereiter</b> an Deiner Seite.</h1>
                  <p className="p-md-4 pb-4 text-center text-md-start">
                    In Sachen <b>Fahrzeugaufbereitung</b> sind wir die Nummer eins im Pott!<br /><br />
                    Profitiere von unserer jahrelangen Erfahrung im Bereich der Fahrzeugaufbereitung. Egal ob große oder kleine Fahrzeuge, mit ein auf Dein Fahrzeug abgestimmtes individuelles Konzept wird auch Dein Fahrzeug wieder im alten Glanz glänzen.<br /><br />
                    Unser Team arbeitet ausschließlich mit professionellen Markenprodukten, um ein perfektes Ergebnis zu erzielen. Zu unseren Leistungen zählt nicht nur die Reinigung des Fahrzeuges, sondern auch das Ausbessern von kleinen bis mittleren Kratzern im Lack. Im Innenraum können wir kleine Wunder entstehen lassen und flicken auch kleine Brandstellen.

                  </p>
                </articel>
              </div>
            </div>
          </div>
          <div className="parallax-bg-w pb-4 pt-2">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 mt-4 mb-4">
                  <h2 className="text-center mt-4 mb-4">Unsere Leistungen im überblick</h2>
                </div>
                <div className="col-12 col-md-4 mb-4">
                  <div className="text-center service">
                    <h4 className="">Reinigen</h4>
                    <LazyLoadImage
                      height="100%" width="100%" className="img-fluid" alt="audi"
                      effect="blur"
                      src={"/cleaning-1837331_1280.jpg"} />
                    <p className="offset-2 col-8">Außenreinigung, Innenraumreinigung, Felgenreinigung und auch Motorwäsche, mit uns bekommst du das, was dein Fahrzeug braucht.</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-4">
                  <div className="text-center service">
                    <h4 className="">Polieren</h4>
                    <LazyLoadImage
                      height="100%" width="100%" className="img-fluid" alt="audi"
                      effect="blur"
                      src={"/car-detailing-7223497_1280.jpg"} />
                    <p className="offset-2 col-8">Wir polieren dein Fahrzeug je nach Zustand des Lackes in mehreren Schritten bis zum Finish.</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-4">
                  <div className="text-center service">
                    <h4 className="">Versiegeln</h4>
                    <LazyLoadImage
                      height="100%" width="100%" className="img-fluid" alt="audi"
                      effect="blur"
                      src={"/cleaning-1837333_1280.jpg"} />
                    <p className="offset-2 col-8">Nano, Keramik oder auch Wachs, passend auf Dein Fahrzeug wählen wir gemeinsam das richtige Mittel, um Dein Fahrzeug zu schützen.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="special_bg" id="contact">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h3 className="text-center mt-4 pt-4">Nimm Kontakt zu uns auf...</h3>
                    <p className="text-center mt-2">und wir senden dir ein unverbindliches Angebot zu.</p>
                    <p className="text-center mt-2">
                       Tel.: <a href="tel:01703571907">01703571907</a><br />
                            E-Mail: <a rel="noreferrer" target='_blank' href="mailto:daniel.wildfoerster@gmail.com">daniel.wildfoerster@gmail.com</a>

                      </p>
                  </div>
                  <div className="col-12 offset-md-2 col-md-8 mt-4 mb-4">
                    <Contact />
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid" id="works">
              <div className="row">
                <div className="col-12 mt-4">
                  <h2 className="text-center mt-4 mb-4">Ein kleiner Auszug unserer Arbeit</h2>
                  <p className="text-center mt-4 mb-4">Überzeuge Dich selbst von unserer Arbeit.</p>
                </div>
                <div className="col-12 offset-md-1 col-md-10 mt-4 mb-4">
                  {!state.sticky && <Gallery />}
                </div>
              </div>
            </div>
          </div>
        </Route>
      </Switch>
      <a target="_blank" href="https://wa.me/+491703571907" className="whatsapp" rel="noreferrer">
        <img src={WhatsApp} alt="whatsapp" width="60" height="60" />
      </a>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="d-flex flex-wrap flex-row mt-3 col-12 justify-content-end align-items-center social-icons">
              <div className="col-12 col-lg-auto my-4 text-center text-lg-end">
                <Scrollspy items={['datenschutz', 'impressum']} currentClassName="active" className="nav-footer">
                  <li><a onClick={() => customHistory.push("/datenschutz")} href="#datenschutz">Datenschutz</a></li>
                  <li><a onClick={() => customHistory.push("/impressum")} href="#impressum">Impressum</a></li>
                </Scrollspy>
              </div>
              <div className="col-12 col-lg-auto my-4 text-center text-lg-end">
                <a href="https://m.facebook.com/Daniel-Wildf%C3%B6rster-113290246971839/" target="_blank" rel="noreferrer"><img className="" src={FacebookImage} alt="facebook" /></a>
                <a href="https://www.instagram.com/wildforsterdaniel/?hl=de" target="_blank" rel="noreferrer"><img className="" src={InstagramImage} alt="instagram" /></a>
                <a href="https://www.tiktok.com/@wildfoerster13?" target="_blank" rel="noreferrer"><img className="" src={TikTokImage} alt="tiktok" /></a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Router>
  );
}

export default App;
