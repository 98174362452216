import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import 'react-lazy-load-image-component/src/effects/blur.css';



export default function App() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

const photos = [
    {
      src: "/gallery/1.jpeg",
      title: "",
      width: 2,
      height: 1
    },
    {
      src: "/gallery/2.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/gallery/3.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/gallery/4.jpeg",
      width: 2,
      height: 2
    },
    {
      src: "/gallery/5.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/gallery/6.jpeg",
      width: 1,
      height: 1
    },
    {
      src: "/gallery/7.jpeg",
      width: 1,
      height: 1
    },
    {
      src: "/gallery/8.jpeg",
      width: 1,
      height: 1
    },
    {
      src: "/gallery/9.jpeg",
      title: "",
      width: 3,
      height: 4
    },
    {
      src: "/gallery/10.jpeg",
      width: 1,
      height: 1
    },
    {
      src: "/gallery/11.jpeg",
      width: 1,
      height: 1
    },
    {
      src: "/gallery/12.jpeg",
      width: 1,
      height: 1
    },
    {
      src: "/gallery/13.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/gallery/14.jpeg",
      width: 1,
      height: 1
    },
    {
      src: "/gallery/15.jpeg",
      width: 1,
      height: 1
    },
    {
      src: "/gallery/16.jpeg",
      width: 1,
      height: 1
    },
    {
      src: "/gallery/17.jpeg",
      title: "",
      width: 3,
      height: 4
    },
    {
      src: "/gallery/18.jpeg",
      width: 1,
      height: 1
    },
    {
      src: "/gallery/19.jpeg",
      width: 1,
      height: 1
    },
    {
      src: "/gallery/20.jpeg",
      width: 1,
      height: 1
    },
    {
      src: "/gallery/21.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/gallery/22.jpeg",
      width: 1,
      height: 1
    },
    {
      src: "/gallery/23.jpeg",
      width: 1,
      height: 1
    },
    {
      src: "/gallery/24.jpeg",
      width: 1,
      height: 1
    }
  ];

  
  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} direction={"row"} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}