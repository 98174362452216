import React from 'react';
import {
    TextField, Button, Select, MenuItem, FormControl, InputLabel, Radio,
    RadioGroup, FormControlLabel, FormLabel, Checkbox, FormGroup, FormHelperText, Divider
} from '@mui/material';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
import { Form, Field } from 'react-final-form'
export default function Contact() {

    const [power, setPower] = React.useState(0);
    const [date, setDate] = React.useState(new Date());
    const Datenschutz = () =>
        <>
            Ich stimme den <a href="/datenschutz#datenschutz" style={{ zIndex: 999 }} target="_blank">Datenschutzbestimmung</a> zu.
        </>
        ;
    const onSubmit = (e) => {
        console.log(e);
    }
    return (
        <Form
            onSubmit={onSubmit}
            onChange={(values) => {
                console.log(values);
                setPower(values.power ? values.power : false);
            }}
            validate={(values) => {
                setPower(values.power ? values.power : false);
                const errors = {};
                if (!values.name) {
                    errors.name = "Erfoderlich";
                }
                if (!values.email) {
                    errors.email = "Erfoderlich";
                }
                if (!values.phone) {
                    errors.phone = "Erfoderlich";
                }
                if (!values.model) {
                    errors.model = "Erfoderlich";
                }
                if (!values.car) {
                    errors.car = "Erfoderlich";
                }
                if (!values.power) {
                    errors.power = "Erfoderlich";
                }
                if (!values.privatepolice) {
                    errors.privatepolice = "Bitte stimme den Datenschutzbestimmungen zu.";
                }
                return errors;
            }}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>

                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                        <div className="row">
                            <div className="col-12 col-md-6  mt-4">
                                <Field
                                    name="name"
                                    render={({ input, meta }) => (
                                        <div>
                                            <TextField error={meta.touched && meta.error} type="text" fullWidth label="Name*" variant="filled" {...input}
                                                helperText={meta.touched && meta.error && meta.error}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="col-12 col-md-6  mt-4">
                                <Field
                                    name="email"
                                    render={({ input, meta }) => (
                                        <div>
                                            <TextField error={meta.touched && meta.error} type="email" fullWidth label="E-Mail*" variant="filled" {...input}
                                                helperText={meta.touched && meta.error && meta.error}
                                            />
                                        </div>
                                    )} />
                            </div>
                            <div className="col-12 col-md-12 mt-4">
                                <Field
                                    name="car"
                                    defaultValue={"Kleinwagen"}
                                    render={({ input, meta }) => (
                                        <div>
                                            <FormControl fullWidth variant="filled" error={meta.touched && meta.error}>
                                                <InputLabel id="car">Fahrzeugklasse*</InputLabel>
                                                <Select
                                                    error={meta.touched && meta.error}
                                                    labelId="car"
                                                    id="power-select"
                                                    {...input}
                                                >
                                                    <MenuItem value={"Motorrad"}>Motorrad</MenuItem>
                                                    <MenuItem style={{ whiteSpace: 'normal' }} value={"Kleinwagen"}>Kleinwagen (z.B. Smart, Opel Corsa)</MenuItem>
                                                    <MenuItem style={{ whiteSpace: 'normal' }} value={"Mittelklassewagen"}>Mittelklassewagen (z.B. VW ID3, Renault Clio)</MenuItem>
                                                    <MenuItem style={{ whiteSpace: 'normal' }} value={"Limousinen"}>Limousinen (z.B. Mercedes S Klasse, Audi A4)</MenuItem>
                                                    <MenuItem style={{ whiteSpace: 'normal' }} value={"SUV"}>SUV (z.B. Audi Q4-Q7)</MenuItem>
                                                    <MenuItem style={{ whiteSpace: 'normal' }} value={"Van - Bus bis 9 Sitzer"}>Van - Bus bis 9 Sitzer</MenuItem>
                                                    <MenuItem style={{ whiteSpace: 'normal' }} value={"Wohnmobil"}>Wohnmobil</MenuItem>
                                                </Select>
                                                {meta.touched && meta.error &&
                                                    <FormHelperText>{meta.error}</FormHelperText>}
                                            </FormControl>
                                        </div>
                                    )} />
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <Field
                                    name="model"
                                    render={({ input, meta }) => (
                                        <div>
                                            <TextField error={meta.touched && meta.error} type="text" fullWidth label="Model*" variant="filled" {...input}
                                                helperText={meta.touched && meta.error && meta.error}
                                            />
                                        </div>
                                    )} />
                            </div>
                            <div className="col-12 col-md-6 mt-4">
                                <Field
                                    name="phone"
                                    render={({ input, meta }) => (
                                        <div>
                                            <TextField error={meta.touched && meta.error} type="phone" fullWidth label="Telefonummer*" variant="filled" {...input}
                                                helperText={meta.touched && meta.error && meta.error}
                                            />
                                        </div>
                                    )} />
                            </div>
                            <div className="col-12 col-md-12 mt-4">
                                <Field
                                    name="power"
                                    defaultValue={"Beratung"}
                                    render={({ input, meta }) => (
                                        <div>
                                            <FormControl fullWidth variant="filled">
                                                <InputLabel id="power">Was soll gemacht werden?*</InputLabel>
                                                <Select
                                                    labelId="power"
                                                    id="power-select"
                                                    label="Age"
                                                    {...input}
                                                >
                                                    <MenuItem value={"Beratung"}>Beratung</MenuItem>
                                                    <MenuItem value={1}>Handwäsche</MenuItem>
                                                    <MenuItem style={{ whiteSpace: 'normal' }} value={2}>Innenraumreinigung</MenuItem>
                                                    <MenuItem style={{ whiteSpace: 'normal' }} value={3}>Handwäsche + Innenraumreinigung</MenuItem>
                                                    <MenuItem style={{ whiteSpace: 'normal' }} value={4}>Handwäsche + Innenraumreinigung + Polsterreinigung</MenuItem>
                                                    <MenuItem style={{ whiteSpace: 'normal' }} value={5}>Handwäsche + Innenraumreinigung + Polsterreinigung + Felgen</MenuItem>
                                                    <MenuItem style={{ whiteSpace: 'normal' }} value={6}>Handwäsche + Innenraumreinigung + Polsterreinigung + Versiegeln</MenuItem>
                                                    <MenuItem style={{ whiteSpace: 'normal' }} value={7}>Individuell</MenuItem>
                                                </Select>
                                                {meta.touched && meta.error &&
                                                    <FormHelperText>{meta.error}</FormHelperText>}
                                            </FormControl>
                                        </div>
                                    )}
                                />
                            </div>
                            {power >= 6 &&
                                <div className="row my-4">
                                    {power === 6 &&
                                        <div className="col-12 col-md-3">
                                            <Field
                                                name="shield"
                                                defaultValue={"keramik"}
                                                render={({ input, meta }) => (
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Wähle das Versiegelmittel</FormLabel>
                                                        <RadioGroup
                                                            aria-label="gender"
                                                            defaultValue={"keramik"}
                                                            name="radio-buttons-group"
                                                            {...input}
                                                        >
                                                            <FormControlLabel value="keramik" control={<Radio />} label="Kermaik" />
                                                            <FormControlLabel value="wax" control={<Radio />} label="Wax" />
                                                            <FormControlLabel value="Nano" control={<Radio />} label="Nano" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                )} />
                                        </div>
                                    }
                                    {power === 7 &&
                                        <>
                                            <div className="col-12 col-lg-4">
                                                <FormGroup>
                                                    <FormLabel component="legend">Reinigung</FormLabel>
                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Handwäsche"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox  {...input}
                                                                />
                                                            )} />
                                                    } label="Handwäsche" />
                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Innenraumreinigung"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox  {...input}
                                                                />
                                                            )} />
                                                    } label="Innenraumreinigung" />
                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Felgenreinigung"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox {...input}
                                                                />
                                                            )} />
                                                    } label="Felgenreinigung" />
                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Motorwäsche"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox  {...input}
                                                                />
                                                            )} />
                                                    } label="Motorwäsche**" />
                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Polsterreigung"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox {...input}
                                                                />
                                                            )} />
                                                    } label="Polsterreigung" />
                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Lederpflege"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox  {...input}
                                                                />
                                                            )} />
                                                    } label="Lederpflege" />
                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Alcantara Reinigung"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox  {...input}
                                                                />
                                                            )} />
                                                    } label="Alcantara Reinigung" />
                                                </FormGroup>
                                            </div>
                                            <div className="col-12 col-lg-4">
                                                <FormGroup>
                                                    <FormLabel component="legend">Polieren & Versiegeln</FormLabel>
                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Finish Polierung"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox  {...input}
                                                                />
                                                            )} />
                                                    } label="Finish Polierung**" />

                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Kneten + Polieren"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox  {...input}
                                                                />
                                                            )} />
                                                    } label="Kneten + Polieren**" />

                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Fahrzeug Versiegeln (Keramik)"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox  {...input}
                                                                />
                                                            )} />
                                                    } label="Fahrzeug Versiegeln (Keramik)" />

                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Fahrzeug Versiegeln (Wax)"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox  {...input}
                                                                />
                                                            )} />
                                                    } label="Fahrzeug Versiegeln (Wax)" />

                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Fahrzeug Versiegeln (Nano)"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox  {...input}
                                                                />
                                                            )} />
                                                    } label="Fahrzeug Versiegeln (Nano)" />

                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Scheiben Versiegeln"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox  {...input}
                                                                />
                                                            )} />
                                                    } label="Scheiben Versiegeln" />

                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Scheiben Versiegeln (Keramik)"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox  {...input}
                                                                />
                                                            )} />
                                                    } label="Scheiben Versiegeln (Keramik)" />

                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Felgen Versiegeln"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox  {...input}
                                                                />
                                                            )} />
                                                    } label="Felgen Versiegeln" />

                                                    <FormControlLabel control={
                                                        <Field
                                                            name="Leder Versiegeln"
                                                            defaultValue={false}
                                                            render={({ input, meta }) => (
                                                                <Checkbox  {...input}
                                                                />
                                                            )} />
                                                    } label="Leder Versiegeln" />

                                                </FormGroup>
                                            </div>
                                            <div className="col-12 col-lg-4">
                                                <FormGroup>
                                                    <FormLabel component="legend">Sonder</FormLabel>
                                                    <FormControlLabel control={<Checkbox />} label="Ozon Behandlung" />
                                                    <FormControlLabel control={<Checkbox />} label="Klimareinigung" />
                                                    <FormControlLabel control={<Checkbox />} label="Fußmattenreinigung" />
                                                    <FormControlLabel control={<Checkbox />} label="Imprägnierung Stoff" />
                                                    <FormControlLabel control={<Checkbox />} label="Imprägnierung Leder" />
                                                    <FormControlLabel control={<Checkbox />} label="Scheinwerfer Aufbereitung" />
                                                </FormGroup>
                                            </div>
                                            <Divider className="my-2" />
                                            <small>** einschätzung nur vorort</small>
                                        </>
                                    }
                                </div>
                            }
                            <div className="col-12 col-md-6  mt-4">
                                <Field
                                    name="date"

                                    render={({ input, meta }) => {
                                        input.onChange(date);
                                        return (
                                            <MobileDatePicker
                                                label="Wunsch Termin"
                                                defaultValue={new Date()}
                                                inputFormat="dd.MM.yyyy"
                                                minDate={new Date()}
                                                okText="Weiter"
                                                cancelText="Abbrechen"
                                                onChange={dt => setDate(dt)} value={date}
                                                renderInput={(params) => <TextField defaultValue={new Date()} variant="filled" fullWidth {...params}  {...input} />}
                                            />
                                        )
                                    }} />
                            </div>
                            <div className="col-12 col-md-12 mt-4">
                            <Field
                                    name="message"
                                    render={({ input, meta }) => (
                                <TextField fullWidth label="Nachricht" variant="filled" multiline rows={6} {...input}/>
                                    )} />
                            </div>
                            <div className="col-12 col-md-12 my-4">
                                <Field
                                    name="privatepolice"
                                    defaultValue={false}
                                    render={({ input, meta }) => (
                                        <FormControl
                                            required
                                            error={meta.error}
                                            component="fieldset"
                                            variant="standard"
                                        >
                                        <FormLabel component="legend">Datenschutz</FormLabel>
                                            <FormGroup error={meta.error} >
                                                <FormControlLabel control={
                                                    <Checkbox  {...input} />
                                                } label={<Datenschutz />} />
                                                {meta.touched && meta.error &&
                                                    <FormHelperText>{meta.error}</FormHelperText>}
                                            </FormGroup>
                                        </FormControl>
                                    )} />
                            </div>
                            <div className="col-12 col-md-12 mt-4 d-flex justify-content-end">
                                <Button variant="contained" color="primary" type="submit" disabled>Anfrage senden</Button>
                            </div>
                        </div>
                    </LocalizationProvider>
                </form>
            )}
        />
    );
}